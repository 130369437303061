@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* body */
body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

body.gradient {
  background-color: rgb(246, 133, 34, 0.1);
  background: linear-gradient(
    to bottom right,
    rgb(246, 133, 34, 75%),
    rgb(250, 162, 84, 80.26%),
    rgb(255, 191, 135, 60%),
    rgb(255, 220, 189, 38.55%),
    rgb(255, 255, 255, 12%),
    rgb(209, 241, 255, 24.45%),
    rgb(167, 228, 255, 36%),
    rgb(100, 183, 220, 34.44%),
    rgb(39, 142, 187, 33%),
    rgb(39, 142, 187, 66.76%),
    rgb(39, 142, 187, 62%)
  );
  background-repeat: no-repeat;
  background-position: center;
}

* {
  font-family: "DM Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 1);
  /* check css */
  /* border: 1px solid red !important;
  box-shadow: 0 0 5px rgba(0,0,255,0.5) !important;  */
}

/* hr */
.hr {
  border-top: 2px solid #d9d9d9;
}

/* article  */
.article-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 280px;
  background-color: #ffffff;
}

.article-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.article-card-title-name {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  white-space: normal;
}

.article-img {
  width: 100%;
  object-position: center;
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

/* forms */
.form-group {
  width: 75%;
}

.form-control,
.form-select {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.btn.btn-outline-secondary.bg-white {
  border: 1px solid #ccc;
}

.form-control:focus,
.form-select:focus {
  border: 2px solid #1c7690 !important;
  border-color: #1c7690;
  opacity: 85%;
  box-shadow: none;
}

.form-control:read-only:focus,
.form-select:read-only:focus {
  border: 1px solid #ccc !important;
  border-color: transparent;
  box-shadow: none;
}

/* nav */
.min-vh-100.confirm {
  background-color: rgb(246, 133, 34, 0.1);
  background: linear-gradient(
    to bottom right,
    rgb(246, 133, 34, 75%),
    rgb(250, 162, 84, 80.26%),
    rgb(255, 191, 135, 60%),
    rgb(255, 220, 189, 38.55%),
    rgb(255, 255, 255, 12%),
    rgb(209, 241, 255, 24.45%),
    rgb(167, 228, 255, 36%),
    rgb(100, 183, 220, 34.44%),
    rgb(39, 142, 187, 33%),
    rgb(39, 142, 187, 66.76%),
    rgb(39, 142, 187, 62%)
  );
  background-repeat: no-repeat;
  background-position: center;
  /* overflow: hidden; */
}

/* branding */
.branding {
  top: 3%;
  z-index: 999;
  position: sticky;
  display: flex;
  justify-content: center;
  left: 28%;
}

/* login */
.login-container {
  background-color: rgb(246, 133, 34, 0.1);
  background: linear-gradient(
    to bottom right,
    rgb(246, 133, 34, 75%),
    rgb(250, 162, 84, 80.26%),
    rgb(255, 191, 135, 60%),
    rgb(255, 220, 189, 38.55%),
    rgb(255, 255, 255, 12%),
    rgb(209, 241, 255, 24.45%),
    rgb(167, 228, 255, 36%),
    rgb(100, 183, 220, 34.44%),
    rgb(39, 142, 187, 33%),
    rgb(39, 142, 187, 66.76%),
    rgb(39, 142, 187, 62%)
  );
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* card */
.card {
  background: rgba(248, 248, 248, 1);
  border-color: transparent;
  margin: 6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.card-img-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.card-img-wrapper {
  border-radius: 10px;
  overflow: hidden;
}

.card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

.card-img-top {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  border-radius: 10px;
}

.card-img-top-sq {
  width: 100%;
  height: auto;
  aspect-ratio: 2/2;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.card-img-wrapper {
  position: relative;
}

/* text */
a {
  text-decoration: none;
}

.blue-text {
  color: rgba(28, 118, 144, 1);
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
}

.blue-text-small {
  color: rgba(28, 118, 144, 1);
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.h2-bold {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
}

.h5 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.h6 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.info-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.danger {
  color: red;
  font-size: 11px;
  text-align: left;
}

.danger-lg {
  color: red;
  font-size: 14px;
  text-align: left;
}

.font-size14 {
  font-size: 14px;
}

.is-invalid,
.is-invalid.form-control {
  border: 1px solid red !important;
}

.invalid-feedback,
.input-error {
  color: #e91e63;
  display: block;
}

.p-success {
  font-size: 17px;
  width: 443px;
  text-align: center;
  font-style: italic;
  line-height: 22px;
  margin-top: 10px;
}

/* buttons */
.primaryBtnSm,
.primaryBtn {
  border-radius: 14px;
  background: #1c7690;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: rgba(255, 255, 255, 1);
  height: 41px;
  width: 100%;
  padding: 10px 16px;
}

.primaryBtnSm[disabled] {
  /* Example styles for disabled state */
  background-color: #658d98;
  box-shadow: none;
  color: white;
  cursor: not-allowed;
  /* Add other styles as needed */
}

.secondaryBtnSm,
.secondaryBtnEdit {
  border-radius: 14px;
  border: 2px solid #1c7690;
  background-color: transparent;
  padding: 10px 16px;
  height: 41px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  color: #1c7690;
}

.secondaryBtn {
  border-radius: 14px;
  border: 2px solid #1c7690;
  background-color: transparent;
  padding: 13px 16px;
  height: 41px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c7690;
  width: 100%;
  text-decoration: none !important;
}

lib-primary-btn.btn.btn-link {
  text-decoration: none;
}

/* images */
.btnImg {
  margin-right: 8px;
}

.icon-link {
  position: absolute;
  bottom: 6px;
  right: 15px;
  background-color: transparent;
  border-radius: 50%;
  z-index: 1;
}

.plus-icon {
  height: 25px;
}

.brand-logo-family {
  display: flex;
  justify-content: center;
}

/* branding */
.petszel-branding.small-size {
  display: flex;
  justify-content: center;
}
.petszelLogo.small-size {
  max-width: 70px;
}
.brandLogo{
  cursor: pointer;
}
.brandLogo.small-size {
  max-width: 150px;
  max-height: 50px;
}
.petszelLogoSolo.large-size {
  height: 50px;
  width: fit-content;
  max-width: 180px;
  max-height: 50px;
  padding: 0 20px 0 20px;
}
.petszelLogo.large-size {
  width: fit-content;
  height: 50px;
}
.petszel-branding.large-size {
  height: fit-content;
  margin: auto;
}
.brandLogo.large-size {
  width: auto;
  max-width: 350px;
  max-height: 80px;
}
.powered.large-size {
  width: 54px;
  height: 13px;
  font-size: 10px;
  line-height: 13px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
/* change password */
.reset-box {
  background-color: rgb(246, 133, 34, 0.1);
  background: linear-gradient(
    to bottom right,
    rgb(246, 133, 34, 75%),
    rgb(250, 162, 84, 80.26%),
    rgb(255, 191, 135, 60%),
    rgb(255, 220, 189, 38.55%),
    rgb(255, 255, 255, 12%),
    rgb(209, 241, 255, 24.45%),
    rgb(167, 228, 255, 36%),
    rgb(100, 183, 220, 34.44%),
    rgb(39, 142, 187, 33%),
    rgb(39, 142, 187, 66.76%),
    rgb(39, 142, 187, 62%)
  );
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.logo {
  width: 100px;
  height: 31.27px;
}

.logoLg {
  width: 150px;
  height: 46.902px;
}

.box-border-password {
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  margin-right: auto;
  margin-left: auto;
  padding: 30px 60px;
  z-index: 1;
}

.confirm-password {
  font-size: 10px;
  padding: 0;
}

p.requirements {
  margin: 10px 0 0 0;
  font-weight: 600;
}

.btn.btn-primary {
  background: rgba(28, 118, 144, 1);
  width: auto;
  height: 41px;
  border-color: rgba(28, 118, 144, 1);
  border-radius: 10px;
  margin-top: 0;
}

.loading-col {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bi-telephone-fill::before {
  transform: scaleX(-1);
}

@keyframes load {
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.spinner-message {
  text-align: center;
}

/* CMS */
.boxer-jumbotron {
  height: 1000px;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  position: relative;
  z-index: 1;
  width: 100%;
}
/* Styles for the table */
.articles table {
  width: 100%; /* Or any other width you prefer */
  border-collapse: collapse; /* Removes spacing between cells */
}

/* Header style for th elements */
.articles th {
  background-color: #f68522; /* Dark orange for the header */
  font-weight: bold;
  padding: 10px; /* Add some spacing */
  color: white;
  border: 1px solid white; /* Light border for cells */
}

/* Striped rows */
.articles table tbody tr:nth-child(odd) {
  background: rgba(255, 211, 187, 0.5);
}

.articles table tbody tr:nth-child(even) {
  background-color: white;
}

/* Styling for table cells */
.articles table tbody tr td {
  padding: 10px; /* Add some spacing */
  border: 1px solid white; /* Light border for cells */
}

.articles table tr th p {
  color: white;
  font-size: 20px;
}

.articles h2 {
  font-size: 25px;
  font-weight: 700;
}

.articles ul {
  list-style-type: none; /* Reset list style for ul */
}

.articles ul li {
  list-style: none; /* Reset list style for li */
  background-image: url("../../../../petszel-owner/src/assets/images/triangle.png");
  background-size: 23px auto; /* Adjust image sizing as needed (e.g., 20px width) */
  background-repeat: no-repeat; /* Prevent image from repeating */
  background-position: left center; /* Adjust image alignment as needed */
  padding-left: 20px; /* Add left padding to create space for the image */
}

.articles ul li::marker {
  content: ""; /* Remove the marker content */
}

.articles ul li p {
  margin-left: 10px;
}

.articles ul {
  padding-left: 0;
}

.ctaImageAndText,
.ctaProductAndService {
  min-height: 200px;
  margin-bottom: 50px;
  margin-top: 50px;
  width: 100%;
}
.ctaImageAndText a div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ctaProductAndService {
  display: flex;
}

.ctaImageAndText a img,
.ctaProductAndService img.custom-image-class {
  margin-right: 15px;
  height: 200px;
  width: fit-content;
  display: flex;
  padding: 5px;
}

.ctaImageAndText a {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-around;
}
/*
.ctaImageAndText a img {
  margin-right: 15px;
} */

/* .ctaImageAndText a div p,
.cta-text > p:first-child,
.articles .ctaProductAndService div p,
.cta-text > p:first-child {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
} */

/* This will target the second <p> tag inside any <div> and make its text normal */
.cta-text > p:nth-child(2) {
  font-size: 20px;
  padding: 10px;
}

.ctaImageAndText a div {
  width: 100%;
}

.ctaImageAndText p:nth-child(2) {
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
}

.articles img {
  width: 100%;
}

.articles h3,
.articles h3 strong {
  font-size: 18px;
  color: #1c7690;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 25px;
}

/* .articles h4 {
  text-align: center;
} */

.articles p u {
  font-weight: bold;
}

.articles h2 em {
  font-size: 25px;
  color: rgba(246, 133, 34, 1);
}

img.cta-image {
  height: 200px;
}

.articles p a {
  color: #218faf;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.articles a .articles h6 a {
  color: #218faf;
  text-decoration: none;
}

.cta-card {
  display: flex; /* Make this a flex container */
  align-items: center; /* Vertically align children in the middle */
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pcta-card {
  display: flex; /* Make this a flex container */
  align-items: center; /* Vertically align children in the middle */
  width: 100%;
}

.card-img-content {
  margin-right: 20px; /* Space between the image and the text */
  /* Adjust width/height or other styles as necessary */
}

.relevant-card-body {
  flex: 1; /* Allows the text part to occupy the remaining width */
  text-align: left; /* Align text to the left */
}

.articles div.insight.trick {
  width: 100%;
  height: 185px;
  border-radius: 10px;
  background-color: #d1e8ff;
  filter: drop-shadow(4px 4px 10px rgba(137, 158, 179, 0.42));
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 25px;
  background-image: url("../images/Hat.png");
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 2%;
  background-position-y: 15%;
}

.articles div.insight.tip {
  flex-direction: column;
  width: 100%;
  height: 185px;
  border-radius: 10px;
  background-color: #b0d9eb;
  filter: drop-shadow(4px 4px 10px rgba(28, 118, 144, 0.26));
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 25px;
  margin-top: 25px;
  background-image: url("../images/lightbulb.png");
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 2%;
  background-position-y: 15%;
}

.articles div.insight.fact {
  flex-direction: column;
  width: 100%;
  height: 185px;
  border-radius: 10px;
  background-color: #ffe9dd;
  filter: drop-shadow(4px 4px 10px rgba(202, 99, 8, 0.2));
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 25px;
  margin-top: 25px;
  background-image: url("../images/funfact.png");
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 2%;
  background-position-y: 15%;
}

.articles h2 {
  font-size: 25px;
  font-weight: bold;
  color: #f68522;
}

.articles div.insight.fact h1,
.articles div.insight.tip h1,
.articles div.insight.trick h1 {
  width: 85%;
}

.articles h4 em {
  font-size: 25px;
  font-family: Georgia, "Times New Roman", Times, serif;
  position: relative; /* Makes sure the pseudo-elements position relative to this element */
  padding-left: 30px; /* Space for the left image */
  padding-right: 30px; /* Space for the right image */
  margin-left: auto;
  margin-right: auto;
}

.articles h6 {
  font-size: 18px;
  font-weight: 500;
}

.articles blockquote p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 60px; /* Adjust to your combined image widths */
  height: 50px; /* Adjust to your image height */
  background: url("../images/quote-L.png") no-repeat left center,
    url("../images/quote-L.png") no-repeat right center;
}

.articles blockquote p::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 60px; /* Adjust to your combined image widths */
  height: 50px; /* Adjust to your image height */
  background: url("../images/quote-R.png") no-repeat left center,
    url("../images/quote-R.png") no-repeat right center;
}

.articles blockquote p {
  font-size: 25px;
  width: fit-content;
  font-family: Georgia, "Times New Roman", Times, serif;
  position: relative; /* Makes sure the pseudo-elements position relative to this element */
  padding-left: 60px; /* Space for the left image */
  padding-right: 60px; /* Space for the right image */
  margin-left: auto;
  margin-right: auto;
}
.articles h5 {
  border-radius: 5px;
  background: rgba(28, 118, 144, 0.3);
  padding: 20px;
  color: #0a303c;
  font-size: 20px;
  font-weight: bold;
}
.articles img {
  height: 500px;
  object-fit: cover;
}
.articles p em {
  color: #1c7690;
  font-weight: bold;
}

.articles p strong {
  font-weight: bold;
}

.articles p a strong {
  color: #218faf;
}

.articles .ctaImageAndText,
.articles .ctaProductAndService {
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 25px;
}

.articles .ctaImageAndText input[type="button"],
a.cta-button,
.articles .ctaProductAndService input[type="button"],
a.cta-button {
  border-radius: 14px;
  background: #1c7690;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: rgba(255, 255, 255, 1);
  height: 41px;
  width: 100%;
  padding: 10px 16px;
  width: auto;
}
.articles .ctaProductAndService div {
  width: 100%;
  text-align: center;
  padding-top: 10pt;
  padding-bottom: 10pt;
}

.articles .ctaProductAndService div a input[type="button"] {
  justify-content: space-around;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.articles div.article,
.articles div.article-video a {
  background: rgba(248, 248, 248, 1);
  border-color: transparent;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  margin: 0 auto 25px auto;
  width: 366px;
}

.articles div.article img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 5px;
}

.articles div.article-video a div.player-icon {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #ffd3bb;
  min-width: 80px;
}

.articles div.article-video a div.player-icon img.custom-image-class{
  max-width: 80px;
  max-height: 80px;
  height: 80px;
  width: 80px;
  border-radius: 10px;
}

.articles div.article-video a div.player-icon img.no-image{
  display: none;
}

.articles div.article-video a div.player-icon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent transparent transparent white;
  transform: translate(-50%, -50%);
}

.articles div.article-video video {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 5px;
}

.articles div.article-video video::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 20px;
  border-color: transparent transparent transparent white;
  transform: translate(-50%, -50%);
  /* Adjust size of the play symbol */
}


.articles div.article a,
.articles div.article-video div.video-name {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: black;
  padding: 5px;
  display: flex;
  align-items: center;
}

/* email */
.emailbody p {
  font-family: DM Sans;
  font-size: 16px;
  color: #000000;
}
.emailbody a {
  font-family: DM Sans;
  font-size: 16px;
  color: #1c7690;
  text-decoration: none;
}
.emailbody a input {
  border-radius: 14px;
  background: #1c7690;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: rgba(255, 255, 255, 1);
  height: 41px;
  width: fit-content;
  padding: 10px 30px;
}
.vl {
  border-right: 1px solid black;
  margin: 10px;
  padding: 10px;
  height: 50px;
}
a.button {
  border-radius: 14px;
  background: #1c7690;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  color: rgba(255, 255, 255, 1);
  height: 41px;
  width: fit-content;
  padding: 10px 30px;
}

div div p a {
  font-family: DM Sans;
  font-size: 16px;
  color: #1c7690;
  text-decoration: none;
}

.orange-box {
  width: 46px; /* Adjust the width as needed */
  height: 46px; /* Adjust the height as needed */
  background-color: #f68522;
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: center; /* Horizontally center items */
  margin-left: 10px; /* Adjust the margin as needed to create spacing */
  border-radius: 5px; /* Add rounded corners */
}

.author-box {
  width: 46px; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: center; /* Horizontally center items */
  margin-left: 10px; /* Adjust the margin as needed to create spacing */
  border-radius: 5px; /* Add rounded corners */
}

.articles div.insight.fact p,
.articles div.insight.tip p,
.articles div.insight.trick p {
  width: 85%;
  display: flex;
}

.bottom-left {
  padding: 5px;
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: white;
  z-index: 999;
}

.title-background {
  background-color: #1c7690;
  border-radius: 100px;
  padding: 10px;
  padding: 10px 20px;
}

.legal {
  margin-top: 1.5rem;
}

/* Add this CSS to style the blue-box */
.blue-box {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 13px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blue-box-fetch {
  border-radius: 10px;
  background: linear-gradient(
    179deg,
    rgba(167, 228, 255, 0.47) 0.73%,
    rgba(167, 228, 255, 0.14) 67.74%
  );
  padding: 10px;
  margin-bottom: 20px;
}

.blue-box-fetch.h4 {
  text-align: center;
}

.blue-box p {
  max-height: 200px; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Add scrolling if necessary */
  word-wrap: break-word; /* Force word wrapping */
  white-space: normal; /* Allow text to wrap onto the next line */
  padding: 0 20px;
}

@media (min-width: 2000px) {
  /* branding */
  .petszelLogo.small-size {
    max-width: 100px;
  }
  .brandLogo.small-size {
    max-width: 225px;
    max-height: 80px;
  }
  .card-img-top {
    object-position: top;
  }
}
@media (max-width: 1650px) {
  /* font */
  .h5 {
    font-size: 25px;
    text-decoration: transparent;
    font-weight: 700;
  }

  .blue-text,
  .info-text {
    font-size: 16px;
  }

  .blue-text-small,
  .secondaryBtnSm,
  .secondaryBtnEdit,
  .primaryBtnSm,
  .primaryBtn,
  .secondaryBtn {
    font-size: 12px;
  }
}
@media (max-width: 1250px) {
  /* font */
  .h5 {
    font-size: 20px;
  }

  .blue-text,
  .info-text {
    font-size: 12px;
  }

  .blue-text-small {
    font-size: 12px;
  }
  .card-title {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }
  .primaryBtnSm {
    padding: 6px;
    height: 38px;
  }

  /* branding */
  .petszelLogo.large-size {
    width: fit-content;
    height: 30px;
  }
  .petszel-branding.large-size {
    width: 80px;
    height: fit-content;
    margin: auto;
  }

  .brandLogo.large-size {
    width: auto;
    height: 45px;
  }

  .powered.large-size {
    width: 54px;
    height: 13px;
    font-size: 10px;
    font-weight: bold;
    line-height: 13px;
    margin: 0;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) and (min-width: 769px) {
.brandLogo.small-size {
  max-width: 120px;
}
}
@media (max-height: 1200px) {
  .boxer-jumbotron {
    height: 750px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .petszelLogoSolo.large-size {
    height: 45px;
  }
}
@media (max-height: 900px) {
  .boxer-jumbotron {
    height: 500px;
  }
}
/* screen size change password */
@media (min-width: 577px) and (max-width: 1600px) {
  .box-border-password {
    width: 500px;
    height: 455px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }
  h3 {
    font-size: 20px;
  }
  .small-text {
    font-size: 12px;
  }
  .confirm-password {
    font-size: 10px;
  }
  label.label {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .vl{
    margin: 0;
    padding: 0;
    height: 30px;
  }
  .cta-card {
    display: flex; /* Make this a flex container */
    align-items: center; /* Vertically align children in the middle */
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    margin-bottom: 20px;
  }
  .pcta-card {
    display: flex; /* Make this a flex container */
    align-items: center; /* Vertically align children in the middle */
    flex-direction: column;
    margin-bottom: 20px;
  }
  a.button {
    font-size: 14px;
  }
  .articles div.article,
  .articles div.article-video {
    width: 100%;
  }
  .legal {
    margin-top: 0;
  }
  .brandLogo.small-size {
    width: fit-content;
    max-height: 30px;
  }
  .petszelLogoSolo.small-size {
    height: 40px;
    width: fit-content;
    max-width: 125px;
    max-height: 32px;
  }
  .petszelLogo.small-size {
    width: fit-content;
    max-width: 70px;
    max-height: 32px;
  }

  .brandLogo.small-size {
    width: 100%;
    height: auto;
    max-width: 150px;
    max-height: 35px;
  }
  .powered.small-size {
    font-size: 6px;
    margin-bottom: 0;
    font-weight: bold;
  }
  /* font */
  .h5 {
    font-size: 18px;
  }

  .blue-text,
  .info-text {
    font-size: 13px;
  }

  .blue-text-small {
    font-size: 12px;
  }

  .primaryBtnSm {
    width: fit-content;
    padding: 6px;
    height: 38px;
  }

  .card-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
  .h3 {
    font-size: 20px;
  }

  /* branding */
  .branding {
    top: 1%;
    z-index: -1;
    position: sticky;
    display: flex;
    justify-content: center;
    width: auto;
  }

  .centered-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* CMS */
  .articles blockquote p,
  .articles h2,
  .articles h2 em,
  .articles div h1 strong,
  /* .ctaImageAndText a div p,
  .cta-text > p:first-child, */
  /* .articles .ctaProductAndService div p,
  .cta-text > p:first-child, */
  .articles h4 em {
    font-size: 20px;
  }
  .cta-text > p:nth-child(2) {
    font-size: 17px;
  }
  .custom-image-class {
    width: 100%;
  }
  .articles blockquote p::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 35px; /* Adjust to your combined image widths */
    height: 30px; /* Adjust to your image height */
    background: url("../images/quote-L-sm.png") no-repeat left center,
      url("../images/quote-L-sm.png") no-repeat right center;
  }
  .articles h5 {
    font-size: 18px;
    text-align: center;
    padding: 15px;
  }
  .articles blockquote p {
    font-size: 15px;
    padding-left: 40px; /* Space for the left image */
    padding-right: 40px; /* Space for the right image */
  }
  .articles blockquote p::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 35px; /* Adjust to your combined image widths */
    height: 30px; /* Adjust to your image height */
    background: url("../images/quote-R-sm.png") no-repeat left center,
      url("../images/quote-R-sm.png") no-repeat right center;
  }
  .custom-image-class {
    width: 100%;
  }
  .articles div.ctaImageAndText a {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .articles div.ctaProductAndService {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ctaProductAndService {
    height: auto;
    padding-top: 5px;
    padding-bottom: 20px;
  }
  .articles div.ctaImageAndText a img {
    margin-right: 0;
    margin-bottom: auto;
  }

  .articles div.ctaImageAndText a div {
    flex-direction: column;
    padding: 10px;
    text-align: center;
  }
  .articles div.ctaImageAndText {
    height: fit-content;
  }
  .articles div.ctaImageAndText a {
    margin-bottom: auto;
    flex-grow: 1;
  }
  .articles img {
    height: 200px;
  }
  .articles div.insight.trick {
    background-image: url("../images/Hat-sm.png");
    background-position-x: 2%;
    background-position-y: 10%;
  }

  .articles div.insight.tip {
    background-image: url("../images/lightbulb-sm.png");
    background-position-x: 2%;
    background-position-y: 10%;
  }

  .articles div.insight.fact {
    background-image: url("../images/funfact-sm.png");
    background-position-x: 2%;
    background-position-y: 10%;
  }

  .articles div.insight.fact p,
  .articles div.insight.tip p,
  .articles div.insight.trick p {
    padding-left: 0;
  }

  .articles div.insight.fact,
  .articles div.insight.tip,
  .articles div.insight.trick {
    display: flex;
    align-items: flex-end;
    padding: 10px;
  }

  .articles div.ctaImageAndText a img {
    margin-bottom: 20px;
  }

  .boxer-jumbotron {
    height: 275px;
  }
  .bottom-left {
    padding: 5px;
    position: absolute;
    bottom: 20px;
    left: 5%;
    color: white;
    z-index: 999;
    width: 70%;
  }
  .orange-box {
    width: 30px; /* Adjust the width as needed */
    height: 30px; /* Adjust the height as needed */
    background-color: #f68522;
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    margin-left: 10px; /* Adjust the margin as needed to create spacing */
    border-radius: 5px; /* Add rounded corners */
  }
  .author-box {
    width: 30px; /* Adjust the width as needed */
    height: auto; /* Adjust the height as needed */
    display: flex;
    align-items: center; /* Vertically center items */
    justify-content: center; /* Horizontally center items */
    margin-left: 10px; /* Adjust the margin as needed to create spacing */
    border-radius: 5px; /* Add rounded corners */
  }
  .articles div.insight.fact h1,
  .articles div.insight.tip h1,
  .articles div.insight.trick h1 {
    width: 85%;
  }
  .articles .ctaImageAndText input[type="button"] {
    margin-bottom: 15px;
  }
  .articles div.ctaImageAndText a img,
  .articles div.ctaProductAndService a img {
    margin-bottom: 20px;
    height: auto;
  }
  .cta-buttons {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* screen size change password*/
@media (max-width: 576px) {
  /* box */
  .box-border-password {
    width: 336px;
    height: 425px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }
  /* font */
  input,
  label {
    font-size: 12px;
  }
  h3 {
    font-size: 20px;
  }
  .confirm-password {
    font-size: 9px;
    padding: 0;
  }
  label.label {
    font-size: 12px;
  }
  #password {
    margin-bottom: 0;
  }
  /* button */
  /* .btn.btn-outline-primary {
    display: none;
  } */
  .btn.btn-primary {
    background: rgba(28, 118, 144, 1);
    width: 103px;
    height: 41px;
    border-color: rgba(28, 118, 144, 1);
    border-radius: 10px;
    margin-top: 0;
  }
  .span-btn-customer {
    margin-top: 20px;
  }
  @media (max-width: 575px) and (min-width: 200px) {
    .petszelLogoSolo.small-size {
      height: 40px;
      width: fit-content;
      max-width: 125px;
      max-height: 32px;
    }
    .petszelLogo.small-size {
      width: fit-content;
      max-width: 60px;
      max-height: 25px;
    }

    .brandLogo.small-size {
      width: 100%;
      height: auto;
      max-width: 150px;
      max-height: 50px;
    }
    .powered.small-size {
      font-size: 6px;
      margin-bottom: 0;
      font-weight: bold;
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-section,
  .print-section * {
    visibility: visible;
  }
  .print-section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  lib-navigation,
  .sidebar-height {
    display: none !important;
  }
  .print-button {
    display: none;
  }
}


.toast .toast-body {
  color: white !important;
}


th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
  no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

th[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
