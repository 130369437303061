@import "projects/petszel-shared/src/assets/css/shared-styles.css";
body.gradient {
  background-color: rgb(246, 133, 34, 0.1);
  background: linear-gradient(
    to bottom right,
    rgb(246, 133, 34, 75%),
    rgb(250, 162, 84, 80.26%),
    rgb(255, 191, 135, 60%),
    rgb(255, 220, 189, 38.55%),
    rgb(255, 255, 255, 12%),
    rgb(209, 241, 255, 24.45%),
    rgb(167, 228, 255, 36%),
    rgb(100, 183, 220, 34.44%),
    rgb(39, 142, 187, 33%),
    rgb(39, 142, 187, 66.76%),
    rgb(39, 142, 187, 62%)
  );
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.cta_product_name{
  display: none!important;
}

.document-card a{
  color: #1c7690 !important;
  font-weight: bold;
  cursor: pointer;
}

/* pet default */
.pet-img-container {
  border: 1px transparent solid;
  border-radius: 100%;
  background-color: #f68522;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pet-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container while maintaining its aspect ratio */
  border-radius: 100%;
  width: 100px;
  height: 100px;
}

.pet-img.default-img {
  width: 50%;
  height: 50%;
  object-fit: contain; /* Displays the default image without cropping it */
}

/* video player */
vg-player{
  justify-content: center!important;
}

vg-scrub-bar-current-time .background {
  background-color: #f68522 !important;
  z-index: 1 !important;
}

[class*=" vg-icon-"],
[class^="vg-icon-"],
vg-playback-button .button,
vg-time-display span {
  color: white !important;
}

vg-scrub-bar .scrubBar {
  align-items: normal!important;
}

vg-scrub-bar-current-time .slider {
  background-color: #f68522 !important;
  z-index: 1 !important;
}

.rotate-and-scale {
  transform: rotate(90deg)!important;
  transform-origin: center center!important;
  /* Adjust the width and height if needed, and ensure it fits within the viewport */
  width: 100vh!important; /* Example - you may need to adjust this */
  height: 100vw!important; /* Example - you may need to adjust this */
}

.clickable-section:hover .image-container .image-vet {
  opacity: 0.5; /* Change the opacity of the image with class .image-vet on hover */
}

.image-text-container,
.image-vet {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.font-color {
  color: #1c7690;
  font-weight: 700;
}

.image-container {
  flex: 1;
  position: relative; /* Add relative positioning for pseudo-element */
  border-right: thin rgba(0, 0, 0, 0.25) solid;
  border-top: thin rgba(0, 0, 0, 0.25) solid;
  border-bottom: thin rgba(0, 0, 0, 0.25) solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  height: 215px;
}

.text-container {
  flex: 1;
  padding: 10px;
  height: 215px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-left: thin rgba(0, 0, 0, 0.25) solid;
  border-top: thin rgba(0, 0, 0, 0.25) solid;
  border-bottom: thin rgba(0, 0, 0, 0.25) solid;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.text-container h3 {
  margin-top: 0;
  font-size: 18px;
}

.text-container p {
  margin-bottom: 0;
}

.image-container img {
  max-height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out; /* Add the transition property for the image */
}

img.image-vet {
  border-radius: 10px;
}

p strong {
  color: #1c7690;
}

/* insights */
div.card-text h2 {
  font-size: 20px;
  font-weight: 600;
  color: black;
  z-index: 1;
}

div.card-text p {
  color: black;
}

/* Default styles for images articles */
.custom-image-class {
  max-width: 100%;
  height: auto;
}

:root #surveyElement {
  --sjs-general-backcolor: rgba(255, 255, 255, 1);
  --sjs-general-backcolor-dim: #ffffff;
  --sjs-general-backcolor-dim-light: rgba(255, 255, 255, 1);
  --sjs-primary-backcolor: #1c7690;
  --sjs-primary-backcolor-light: rgba(28, 118, 144, 0.1);
  --sjs-primary-backcolor-dark: rgba(24, 100, 122, 1);
  --sjs-base-unit: 8px;
  --sjs-corner-radius: 14px;
  --sjs-shadow-small: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  --sjs-shadow-medium: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  --sjs-shadow-large: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
  --sjs-shadow-inner: 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
  --sjs-border-light: rgba(0, 0, 0, 0.15);
  --sjs-border-default: rgba(0, 0, 0, 0.15);
  --sjs-general-backcolor-dark: rgba(248, 248, 248, 1);
  --sjs-general-backcolor-dim-dark: rgba(243, 243, 243, 1);
  --sjs-general-forecolor: rgba(0, 0, 0, 0.91);
  --sjs-general-forecolor-light: rgba(0, 0, 0, 0.45);
  --sjs-general-dim-forecolor: rgba(0, 0, 0, 0.91);
  --sjs-general-dim-forecolor-light: rgba(0, 0, 0, 0.45);
  --sjs-primary-forecolor: rgba(255, 255, 255, 1);
  --sjs-primary-forecolor-light: rgba(255, 255, 255, 0.25);
  --sjs-secondary-backcolor: rgba(255, 152, 20, 1);
  --sjs-secondary-backcolor-light: rgba(255, 152, 20, 0.1);
  --sjs-secondary-backcolor-semi-light: rgba(255, 152, 20, 0.25);
  --sjs-secondary-forecolor: rgba(255, 255, 255, 1);
  --sjs-secondary-forecolor-light: rgba(255, 255, 255, 0.25);
  --sjs-border-inside: rgba(0, 0, 0, 0.16);
  --sjs-special-red: rgba(229, 10, 62, 1);
  --sjs-special-red-light: rgba(229, 10, 62, 0.1);
  --sjs-special-red-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-green: rgba(25, 179, 148, 1);
  --sjs-special-green-light: rgba(25, 179, 148, 0.1);
  --sjs-special-green-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-blue: rgba(67, 127, 217, 1);
  --sjs-special-blue-light: rgba(67, 127, 217, 0.1);
  --sjs-special-blue-forecolor: rgba(255, 255, 255, 1);
  --sjs-special-yellow: rgba(255, 152, 20, 1);
  --sjs-special-yellow-light: rgba(255, 152, 20, 0.1);
  --sjs-special-yellow-forecolor: rgba(255, 255, 255, 1);
  --font-family: Trebuchet MS, sans-serif;
  --sjs-font-size: 16px;
  --sjs-font-surveytitle-family: Trebuchet MS, sans-serif;
  --sjs-font-surveytitle-weight: 700;
  --sjs-font-surveytitle-color: rgba(22, 22, 22, 1);
  --sjs-font-surveytitle-size: 32px;
  --sjs-font-pagetitle-family: Trebuchet MS, sans-serif;
  --sjs-font-pagetitle-weight: 700;
  --sjs-font-pagetitle-color: rgba(246, 133, 34, 0.91);
  --sjs-font-pagetitle-size: 24px;
  --sjs-font-pagedescription-family: Trebuchet MS, sans-serif;
  --sjs-font-pagedescription-weight: 400;
  --sjs-font-pagedescription-color: rgba(0, 0, 0, 0.45);
  --sjs-font-pagedescription-size: 16px;
  --sjs-question-background: rgba(255, 255, 255, 1);
  --sjs-questionpanel-backcolor: rgba(255, 255, 255, 1);
  --sjs-questionpanel-hovercolor: rgba(248, 248, 248, 1);
  --sjs-questionpanel-cornerRadius: 14px;
  --sjs-font-questiontitle-family: Open Sans;
  --sjs-font-questiontitle-weight: 600;
  --sjs-font-questiontitle-color: rgba(0, 0, 0, 0.91);
  --sjs-font-questiontitle-size: 16px;
  --sjs-font-questiondescription-family: Open Sans;
  --sjs-font-questiondescription-weight: 400;
  --sjs-font-questiondescription-color: rgba(0, 0, 0, 0.45);
  --sjs-font-questiondescription-size: 16px;
  --sjs-editor-background: rgba(255, 255, 255, 1);
  --sjs-editorpanel-backcolor: rgba(255, 255, 255, 1);
  --sjs-editorpanel-hovercolor: rgba(243, 243, 243, 1);
  --sjs-editorpanel-cornerRadius: 14px;
  .sd-root-modern--mobile .sd-body.sd-body--responsive,
  .sd-root-modern--mobile .sd-body.sd-body--static {
    padding: calc(1 * var(--sjs-base-unit, var(--base-unit, 2px)));
  }
  .sd-body.sd-body--static {
    /* max-width: calc(90 * var(--sjs-base-unit, var(--base-unit, 8px))); */
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    padding-bottom: calc(2 * var(--sjs-base-unit, var(--base-unit, 2px)));
  }
  .sd-header__text .sd-title,
  .sd-root-modern .sd-container-modern__title {
    display: none;
  }
}

.ctaImageAndText.hidden,
.ctaProductAndService.hidden {
  display: none;
}

@media (min-width: 2000px) {
  .article-img {
    width: 100%;
    object-position: center;
    max-height: 300px;
    object-fit: cover;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .article-img {
    width: 70px;
    border-radius: 5px;
    height: 70px;
  }

  .article-card-title-name {
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
  }

  .article-description {
    font-size: 11px;
    font-weight: 400;
  }
  .article-card {
    background-color: #ffffff;
    min-height: 105px;
  }
  :root #surveyElement {
    .sd-action-bar.sd-footer.sd-body__navigation.sd-clearfix {
      margin-bottom: 80px;
    }
  }
  span.sd-rating__item-text.sd-rating__min-text,
  span.sd-rating__item-text.sd-rating__max-text {
    display: none;
  }
  vg-controls,
  vg-play-pause,
  vg-playback-button,
  vg-time-display,
  vg-mute,
  vg-volume,
  vg-fullscreen {
    height: 30px !important;
    color: white;
  }
  vg-time-display {
    line-height: 30px !important;
    margin-right: 10px;
  }
  vg-scrub-bar{
    bottom: 30px !important;
  }
}


.sv-ranking-item__text {
  white-space: normal !important;
}

.terms-modal .modal-content .modal-body {
  height: 180px;
  text-align: center;
  font-size: 14px;
}

.btn-primary-custom {
  border-radius: 14px !important;
  border: 2px solid #1C7690 !important;
  background-color: #1C7690 !important;
  color: white !important;
}

.toast-container {
  bottom: 5%;

  .toast:not(.bg-danger) {
    .toast-body {

      i, span {
        color: #212121 !important;
        font-size: 17px;
        line-height: 24px;
      }
    }
  }

  .toast.bg-danger {
    .toast-body {
      i, span {
        color: white !important;
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
}

.toast-container .bg-success,
.alert.bg-success {
  background-color: #4AA564 !important;
}
